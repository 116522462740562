import React from 'react';

import { getStaticAssetPath } from '../../utils/assets';

export const CompaniesUsingLunaPark = React.memo(
  (props: { className?: string }) => {
    return (
      <div
        className={`${
          props.className ?? 'w-full flex justify-center items-center gap-5'
        }`}
      >
        <img
          src={getStaticAssetPath('images/onboarding/spotify.png')}
          alt=''
          className='w-21'
        />
        <img
          src={getStaticAssetPath('images/onboarding/meta.png')}
          alt=''
          className='w-24'
        />
        <img
          src={getStaticAssetPath('images/onboarding/salesforce.png')}
          alt=''
          className='w-14'
        />
        <img
          src={getStaticAssetPath('images/onboarding/microsoft.png')}
          alt=''
          className='w-22'
        />
        <img
          src={getStaticAssetPath('images/onboarding/zoom.png')}
          alt=''
          className='w-20'
        />
      </div>
    );
  }
);

export function CompaniesUsingLunaParkV2(props: {
  width: number;
  align?: 'center' | 'left';
}) {
  return (
    <div
      className='w-140 flex items-center justify-between'
      style={{
        scale: (props.width / 560).toFixed(2),
        transformOrigin: props.align,
      }}
    >
      <img
        src={getStaticAssetPath('images/onboarding/microsoft-v2.png')}
        alt='microsoft'
        className='w-30'
      />
      <img
        src={getStaticAssetPath('images/onboarding/stripe-v2.png')}
        alt='stripe'
        className='w-21'
      />
      <img
        src={getStaticAssetPath('images/onboarding/spotify-v2.png')}
        alt='spotify'
        className='w-25'
      />
      <img
        src={getStaticAssetPath('images/onboarding/meta-v2.png')}
        alt='meta'
        className='w-25'
      />
      <img
        src={getStaticAssetPath('images/onboarding/netflix-v3.png')}
        alt='netflix'
        className='w-22'
      />
    </div>
  );
}
