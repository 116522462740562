const CloseIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`${props.className || 'w-6 h-6 fill-[#BFBFBF]'}`}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path d='M23.025.992a1.743 1.743 0 00-2.467 0L12 9.532 3.443.975a1.743 1.743 0 00-2.468 0 1.743 1.743 0 000 2.467L9.533 12 .975 20.558a1.743 1.743 0 000 2.467 1.743 1.743 0 002.468 0L12 14.468l8.558 8.557a1.743 1.743 0 002.467 0 1.743 1.743 0 000-2.467L14.468 12l8.557-8.558a1.754 1.754 0 000-2.45z'></path>
    </svg>
  );
};

export { CloseIcon };
